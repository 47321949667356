import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "limeriki paladir"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Unser Untergrundrestaurant`}</h3>
    <p>{`Da wo wir sind, treffen wir uns und essen zusammen.
In einem Restaurant geht es um vieles. Um Menschen, um Bedürfnisse, um Nähe und Distanz.
Begleite uns auf der Reise durch die Kulinarik der Welt. Nie dogmatisch, immer arme Leute essen. `}</p>
    <h3>{`Sozialexperiment`}</h3>
    <p>{`Gastronaut*innen willkommen. Wir tanzen gemeinsam durch die Vielfalt der Welt und versuchen alle(s) auf einen Tisch zu bringen.
Wir wollen gemeinsam mit euch eine gute Zeit verbringen. Unser sein genießen. `}</p>
    <h3>{`Der Weg der Gewürze`}</h3>
    <p>{`Was ist dein Stil?
Bei uns trifft die Welt aufeinander, irgendwo zwischen Sibirien, alter Oma und Seidenstraße findest du kulinarisch unsere Heimat.`}</p>
    <p>{`Und manchmal poppen wir einfach irgendwo mit unserer Straßenküche auf.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      